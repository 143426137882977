@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Roboto', sans-serif;
}

.ant-table-wrapper .ant-table-pagination-right {
  justify-content: center;
}

.ant-table-wrapper .ant-table-thead > tr > th {
  background-color: #e8e8e8;
  font-size: 14px;
  font-weight: 500;
}
.ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 7px 16px;
}

.ant-tabs-top > .ant-tabs-nav {
  margin: 0px !important;
}

.ant-select .ant-select-selector {
  border-radius: 4px !important;
}

.ant-select-multiple .ant-select-selector,
.ant-select.ant-select-in-form-item,
.ant-select-single {
  min-height: 40px;
  border-radius: 4px !important;
}

.ant-picker,
.ant-input {
  min-height: 40px !important;
}

.ant-picker-input {
  pointer-events: none;
}

@media only screen and (max-width: 600px) {
  .ant-picker-panel-container .ant-picker-panels {
    flex-direction: column !important;
  }
  .ant-picker-dropdown .ant-picker-cell {
    padding: 0px !important;
    height: 20px !important;
  }
  .ant-picker-dropdown .ant-picker-month-panel .ant-picker-content {
    height: 150px !important;
  }
}
