.datePickerField {
  :global(.react-datepicker-popper) {
    width: 550px;
  }
  :global(.react-datepicker-wrapper) {
    width: 100%;
  }
  @media only screen and (max-width: 768px) {
    :global(.react-datepicker__day-name) {
      width: 20px !important;
    }
    :global(.react-datepicker__day) {
      width: 20px !important;
    }
    :global(.react-datepicker-popper) {
      width: 450px !important;
    }
  }

  @media only screen and (max-width: 450px) {
    :global(.react-datepicker__day-name) {
      width: 13px !important;
    }
    :global(.react-datepicker__day) {
      width: 13px !important;
    }
    :global(.react-datepicker-popper) {
      width: 410px !important;
    }
  }
  :focus-visible {
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
    outline-width: 5px;
    outline: none;
  }
}

.datePickerStart {
  :global(.react-datepicker) {
    margin-left: 100px;
  }
}

.datePickerEnd {
  :global(.react-datepicker) {
    margin-right: 50px;
  }
}
