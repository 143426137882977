.viewMonth {
  :global(.rbc-date-cell) {
    text-align: left;
    padding-left: 10px;
    padding-top: 10px;
  }
  :global(.rbc-header) {
    text-align: left;
    padding: 10px;
    color: #969696;
  }
  :global(.rbc-show-more) {
    background-color: #e8e8e8;
    color: #585757;
    font-size: 12px;
    border-radius: 8px;
    padding: 3px;
    margin-top: 5px;
  }
}
