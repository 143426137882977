.ant-menu {
  .ant-menu-item,
  .ant-menu-item.ant-menu-item-active,
  .ant-menu-submenu {
    .ant-menu-sub .ant-menu-title-content {
      font-weight: 500;
      padding-left: 10px;
    }
  }
  .ant-menu-title-content {
    color: #666666;
    font-weight: 500;
  }
  .ant-menu-submenu.ant-menu-submenu-selected {
    color: #00a0da;

    .ant-menu-submenu-arrow {
      &:before,
      &:after {
        color: rgba(71, 89, 255, 1);
      }
    }

    .ant-menu-submenu-title {
      background-color: rgba(2, 20, 189, 0.22);
      .ant-menu-item-icon {
        filter: sepia(53%) saturate(6044%) hue-rotate(228deg);
      }
      .ant-menu-title-content {
        color: rgba(71, 89, 255, 1);
      }
    }

    .ant-menu-item-selected.ant-menu-item-only-child {
      color: #005e81;
      background-color: rgba(2, 20, 189, 0.06);
    }
  }

  .ant-menu-item.ant-menu-item-selected {
    background-color: rgba(199, 203, 240, 1);
    color: rgba(71, 89, 255, 1);
    .ant-menu-title-content {
      color: rgba(71, 89, 255, 1);
    }

    .ant-menu-item-icon {
      filter: sepia(53%) saturate(6044%) hue-rotate(228deg);
    }

    &.ant-menu-item-active {
      color: rgba(71, 89, 255, 1);
      .ant-menu-item-icon {
        filter: sepia(53%) saturate(6044%) hue-rotate(228deg);
      }
    }

    &:after {
      content: none;
    }
  }
}

.ant-layout {
  height: 100dvh !important;
}
