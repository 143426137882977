.viewDay {
  :global(.react-calendar-timeline .rct-header-root) {
    background-color: #ffffff;
    border-top: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
  }
  :global(.react-calendar-timeline .rct-calendar-header) {
    border: none;
    border-left: 1px solid #e8e8e8;
  }
  :global(.react-calendar-timeline .rct-sidebar .rct-sidebar-row) {
    border-left: 1px solid #e8e8e8 !important;
  }
  :global(
      .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd
    ) {
    background-color: #ffffff;
  }
  :global(.rct-hl-odd) {
    background-color: #ffffff !important;
  }
  :global(.react-calendar-timeline .rct-sidebar) {
    border-right: none;
    text-align: center;
    top: 1px;
  }
  :global(.react-calendar-timeline .rct-scroll) {
    overflow-x: hidden;
  }
  :global(.rct-horizontal-lines) {
    border-top: 1px solid #e8e8e8;
    margin: 0 !important;
    padding: 0 !important;
    box-sizing: border-box;
  }
  :global(.rct-horizontal-line) {
    border-top: 1px solid #e8e8e8;
    margin: 0 !important;
    padding: 0 !important;
    box-sizing: border-box;
  }

  :global(.react-calendar-timeline .rct-vertical-lines .rct-vl:nth-child(odd)) {
    border: none;
    border-left: 1px solid #e8e8e8;
    background-color: #ffffff;
    // z-index: 9999;
  }

  :global(
      .react-calendar-timeline .rct-vertical-lines .rct-vl:nth-child(even)
    ) {
    border: none;
    border-left: 1px solid #e8e8e8;
    background-color: #ffffff;
    // z-index: 9999;
  }
  :global(.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd) {
    // z-index: 9999 !important;
    border-bottom: none;
  }

  :global(.react-calendar-timeline .rct-horizontal-lines .rct-hl-even) {
    position: relative !important;
    // z-index: 9999 !important;
    border: 1px solid #e8e8e8 !important;
  }
  :global(
      .react-calendar-timeline .rct-horizontal-lines .rct-hl-even:nth-child(1)
    ) {
    position: relative !important;
    // z-index: 9999 !important;
    border-top: none !important;
  }
  :global(.react-calendar-timeline .rct-header-root) {
    border-bottom: 1px solid #e8e8e8 !important;
  }
  :global(.react-calendar-timeline .rct-sidebar .rct-sidebar-row) {
    border-bottom: 1px solid #e8e8e8 !important;
  }
  :global(.react-calendar-timeline .rct-dateHeader) {
    border-bottom: 1px solid #e8e8e8 !important;
  }
}
.dateHeader {
  height: 60px !important;
  white-space: pre-wrap;

  :global(.rct-dateHeader-primary) {
    color: #000000 !important;
    border-left: none !important ;
    border-top: none !important ;
  }
}
