.scheduleCU {
  :global(.ant-drawer .ant-drawer-mask) {
    z-index: 999999 !important;
  }
}
.radioScheduleCon {
  :global(.ant-radio-group) {
    width: 100% !important;
  }
  :global(.ant-form-item .ant-form-item-control-input) {
    min-height: 0 !important;
  }
  margin-bottom: 20px;
}
.radioSchedule {
  :global(.ant-radio-wrapper .ant-radio) {
    align-self: flex-start;
    margin-top: 3px;
  }
  :global(.ant-radio-group) {
    width: 100% !important;
  }
  :global(.ant-radio-wrapper span.ant-radio + *) {
    width: 100% !important;
  }
  :global(.ant-space-vertical) {
    width: 100% !important;
  }
}

.disabledText {
  color: #00000040 !important;
}
.formInput {
  width: 100%;
  border-radius: 4px !important;
  :global(.ant-radio-wrapper .ant-radio) {
    align-self: flex-start;
    margin-top: 3px;
  }
  :global(.ant-radio-wrapper span.ant-radio + *) {
    width: 100% !important;
  }
  :global(.ant-space-vertical) {
    width: 100% !important;
  }
  :global(.ant-radio-wrapper) {
    width: 100% !important;
  }
  :global(.ant-form-item) {
    margin-bottom: 0;
  }
}
.radioColor {
  :global(.ant-radio-wrapper .ant-radio) {
    display: none;
  }
}
.formScheduleCU {
  :global(.ant-form-item .ant-form-item-label > label) {
    color: #00000040 !important;
  }
}

.circle1 {
  width: 18px;
  height: 18px;
  background-color: rgba(0, 133, 255, 1);
  border-radius: 50%;
}

.circle2 {
  width: 18px;
  height: 18px;
  background-color: rgba(255, 159, 45, 1);
  border-radius: 50%;
}

.circle3 {
  width: 18px;
  height: 18px;
  background-color: rgba(233, 44, 44, 1);
  border-radius: 50%;
}
.circle4 {
  width: 18px;
  height: 18px;
  background-color: rgba(88, 87, 87, 1);
  border-radius: 50%;
}
.circle5 {
  width: 18px;
  height: 18px;
  background-color: rgba(47, 140, 51, 1);
  border-radius: 50%;
}
.circle6 {
  width: 18px;
  height: 18px;
  background-color: rgba(239, 93, 168, 1);
  border-radius: 50%;
}
.circle7 {
  width: 18px;
  height: 18px;
  background-color: rgba(217, 217, 217, 1);
  border-radius: 50%;
}
.circle8 {
  width: 18px;
  height: 18px;
  background-color: rgba(93, 95, 239, 1);
  border-radius: 50%;
}
.circle9 {
  width: 18px;
  height: 18px;
  background-color: rgba(175, 84, 0, 1);
  border-radius: 50%;
}
